var idPage = {
  P_MAIN: 0,
  P_ADV: 1,
  P_LOADING: 2,
  P_INGAME: 3,
  P_ERROR: 4,
  P_HIST: 5,
  P_ABOUT: 6,
  P_SHOWQUESTION: 7,
  P_SOLUTION: 8,
  P_TESTCODE: 9,
  P_ASKSOLO: 10,
  P_ASKSOLOPAGE1: 11,
  P_ASKSOLOPAGE2: 12,
  P_ASKSOLOPAGE3: 13,
  P_SEARCH: 14,
  P_AREYOUSURE: 15,
  P_RESULTMACHINE: 16,
  P_CREATECHALLENGE: 17,
  P_RECAPTCHATEST: 18
};

export default idPage;
