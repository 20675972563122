var traduction = {
    0: {
        LANG: "Français",
        LANGCODE: "FR",
        QUICK: "JOUER",
        DAiLYCHALLENGE: "Défi du jour",
        CUSTOM: "Partie personnalisée",
        ABOUT: "À propos de Turing Machine",
        LOAD: "CHARGER",
        ERROR_UNABLE: "Impossible de charger le problème",
        COMPETITIVE: "Compétitif",
        SOLO: "SOLO / COOP",
        CLASSIC: "Classique",
        EXTREME: "Extrême",
        NIGHTMARE: "Cauchemar",
        CLASSICSUB: "",
        EXTREMESUB: "(Voir la règle)",
        NIGHTMARESUB: "(Voir la règle)",
        EASY: "Facile",
        MEDIUM: "Standard",
        HARD: "Difficile",
        V4: "4",
        V5: "5",
        V6: "6",
        PLAYAGAME: "Jouer",
        PLAY: "Générer",
        LOADING: "CHARGEMENT",
        CHECKCODE: "Tester un code",
        INPUTCODE: "Entrez votre code",
        TESTCODE: "Vérifier",
        FALSECODE: "Ce code n'est pas le bon.",
        GOODCODE: "Félicitations ! Ce code est le bon.",
        BEATTHEMACHINE: "Avez-vous battu la MACHINE?",
        INPUTSOLOA: "Maintenant, pour savoir si vous avez battu la machine,",
        INPUTSOLOB: "Pour chaque manche, notez les réponses obtenues :",
        CLICBOXES: "Cliquez sur les cases pour les saisir",
        MAX3: "3 questions maximum par round !",
        ADDROUND: "Ajouter une manche",
        DELROUND: "Supprimer la dernière manche",
        SUBMITSOLOQUESTIONS: "SOUMETTRE",
        SOCIALWIN: "J'ai battu la machine !",
        SOCIALTIE: "Match nul contre la machine !",
        SOCIALLOSE: "La machine me bat !",
        YOUWIN: "VICTOIRE !!!",
        CANCEL: "RETOUR",
        SOLUTION: "Solution",
        BACK: "Retour",
        BACKHOME: "Retour à la page d'accueil",
        BACKTOGAME: "Retour au jeu",
        SHOW_SOLUTION: "Êtes-vous sûr de vouloir voir la solution ?",
        YES: "OUI",
        NO: "NON",
        CODE: "CODE",
        SHEET: "Télécharger les feuilles de notes",
        SEARCH: "Chercher un problème",
        STEP1: "Mode",
        STEP2: "Difficulté",
        STEP3: "Vérificateurs",
        CRITERIA: "Cartes Critères",
        CRITERIADOUBLE: "Cartes Critères (2 par vérificateur)",
        CRITERIAMIXED: "Cartes Critères (mélangées)",
        VERIFIER: "Cartes Vérification",
        SOLOMODE: "Solo / Coop",
        CHECKCODESOLO: "MACHINE",
        INPUTROUND: "NOMBRE DE ROUNDS",
        INPUTQUESTION: "NOMBRE DE QUESTIONS",
        WINSOLO0: "Oh non ! <br/> Vous n'avez pas battu la machine.",
        WINSOLO1: "Félicitations ! <br/> Vous avez fais le même score que la machine.",
        WINSOLO2: "Félicitations ! <br/> Vous avez battu la machine.",
        ABOUTLINK: "https://www.scorpionmasque.com/fr/turingmachine",
        DATEFORMAT: "DD/MM/YYYY",
        DOWNLOADRULES: "Télécharger les règles du jeu",
        GAMEHISTORY: "Historique des parties",
        GAMETYPE: "Type de jeu",
        TEXT1: "Récupérer les cartes <span class='green'>Critères</span> et Vérification dans la boîte.",
        INPUTGAMECODE: "Saisissez l'identifiant du problème",
        CHOOSETYPE: "Choix du type de jeu",
        TRYAGAIN: "Réessayer",
        COMPARETOMACHINE: "Comparez-vous avec la machine",
        VSMACHINETEXT1: "Vous avez trouvé le code en <span class='bolder'>{$r}</span> manche(s) et <span class='bolder'>{$q}</span> question(s).",
        VSMACHINETEXT2: "La machine a réussi en <span class='bolder'>{$r}</span> manche(s) et <span class='bolder'>{$q}</span> question(s).",
        INVITEFRIENDS: "Invitez vos amis à réaiser ce problème",
        COPY: "Copier",
        SHARE: "Partager",
        COPIED: "Copié !",
        EXTREMEMODE: "Mode Extrême : voir livret de règles page 3",
        NIGHTMAREMODE: "Mode Cauchemar : voir livret de règles page 3",
        SHARERESULTS: "Copiez et partagez vos résultats à vos amis ou sur vos réseaux sociaux !",
        PASTEYOURRESULT: " Vous pouvez maintenant coller vos résultats sur vos réseaux sociaux et affronter vos amis dans une compétition quotidienne !",
        XPARADOX: "Vous pensez que la machine se trompe ?",
        XPARADOX_FILENAME: "TuringMachine_ParadoxeFaux_FR.pdf",
        PRINTABLECHALLENGES: "Compilation de défis à imprimer",
        CREATECHALLENGE: "Créer un défi",
        NEW: "nouveau",
        CRITERIACARDS: "Cartes Critères",
        CHOOSECARDS: "Choisissez les cartes Critères avec lesquelles vous voulez jouer",
        CLICKTOSELECT: "Cliquez pour sélectionner une carte",
        SELECTCARDS: "Sélectionnez une carte Critère"

    },
    1: {
        LANG: "English",
        LANGCODE: "EN",
        QUICK: "PLAY",
        DAiLYCHALLENGE: "Daily Challenge",
        CUSTOM: "Custom game",
        ABOUT: "About Turing Machine",
        LOAD: "LOAD",
        ERROR_UNABLE: "Unable to load the problem",
        COMPETITIVE: "Competitive",
        SOLO: "Solo/Co-op",
        CLASSIC: "Classic",
        EXTREME: "Extreme",
        NIGHTMARE: "Nightmare",
        CLASSICSUB: "",
        EXTREMESUB: "(See rulebook)",
        NIGHTMARESUB: "(See rulebook)",
        EASY: "Easy",
        MEDIUM: "Standard ",
        HARD: "Hard",
        V4: "4",
        V5: "5",
        V6: "6",
        PLAY: "GENERATE",
        PLAYAGAME: "Play",
        LOADING: "LOADING",
        CHECKCODE: "Test a Code",
        INPUTCODE: "Input your code",
        TESTCODE: "VERIFY",
        FALSECODE: "This code is not correct.",
        GOODCODE: "Congratulations! This is the correct code.",
        BEATTHEMACHINE: "Did you beat the MACHINE?",
        INPUTSOLOA: "Now, to know if you beat the machine,",
        INPUTSOLOB: "For each round, write the answers you got from each Verifier:",
        CLICBOXES: "Click on the boxes to fill them",
        MAX3: "3 questions maximum per round!",
        ADDROUND: "Add a round",
        DELROUND: "Remove last round",
        SUBMITSOLOQUESTIONS: "SUBMIT",
        SOCIALWIN: "I Beat the Machine!",
        SOCIALTIE: "I Tie the Machine!",
        SOCIALLOSE: "The Machine Beat Me!",
        YOUWIN: "VICTORY!!!",
        CANCEL: "BACK",
        SOLUTION: "Solution",
        BACK: "Back",
        BACKHOME: "Back to Homepage",
        BACKTOGAME: "Back to Game",
        SHOW_SOLUTION: "Are you sure that you want to see the solution?",
        YES: "YES",
        NO: "NO",
        CODE: "CODE",
        SHEET: "Download Note Sheets",
        SEARCH: "Problem Search",
        STEP1: "Mode",
        STEP2: "Difficulty",
        STEP3: "Verifiers",
        CRITERIA: "Criteria Cards",
        CRITERIADOUBLE: "Criteria Cards (2 on each verifier)",
        CRITERIAMIXED: "Criteria Cards (mixed)",
        VERIFIER: "Verification Cards",
        SOLOMODE: "Solo/Co-op",
        CHECKCODESOLO: "MACHINE",
        INPUTROUND: "TOTAL ROUNDS PLAYED",
        INPUTQUESTION: "TOTAL QUESTIONS ASKED",
        WINSOLO0: "Oh no!<br/> You didn't beat the machine.",
        WINSOLO1: "Congratulations!<br/> You tied the machine!",
        WINSOLO2: "Congratulations!<br/> You beat the machine!",
        ABOUTLINK: "https://www.scorpionmasque.com/en/turingmachine",
        DATEFORMAT: "MM/DD/YYYY",
        DOWNLOADRULES: "Download Game Rules",
        GAMEHISTORY: "Game History",
        GAMETYPE: "Game Type",
        TEXT1: "Get <span class='green'>Criteria</span> and Verification cards in the box.",
        INPUTGAMECODE: "Input Game ID",
        CHOOSETYPE: "Choose game type",
        TRYAGAIN: "Try again",
        COMPARETOMACHINE: "Compare with the machine",
        VSMACHINETEXT1: "You found the code <br/>in <span class='bolder'>{$r}</span> round(s) and <span class='bolder'>{$q}</span> question(s).",
        VSMACHINETEXT2: "The machine found it in <span class='bolder'>{$r}</span> round(s) and <span class='bolder'>{$q}</span> question(s).",
        INVITEFRIENDS: "Submit this problem to your friends",
        COPY: "Copy",
        SHARE: "Share",
        COPIED: "Copied!",
        EXTREMEMODE: "Extreme Mode: see rulebook page 3\n",
        NIGHTMAREMODE: "Nightmare Mode: see rulebook page 3",
        SHARERESULTS: "Copy and share your results with your friends or on your social media!",
        PASTEYOURRESULT: " You can now paste your results on your social media platforms and take on your friends in a daily competition! ",

        XPARADOX: "Think That the Machine is Wrong?",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_EN.pdf",
        CREATECHALLENGE: "Create a Challenge",
        NEW: "new",
        CRITERIACARDS: "Criteria Cards",
        CHOOSECARDS: "Choose the Criteria cards you want to use",
        SELECTCARDS: "Select a Criteria card",
        CLICKTOSELECT: "Click to select a card",
        PRINTABLECHALLENGES: "Printable Challenge Bank"

    },
    2: {
        LANGCODE: "CNS",
        LANG: "简体中文",
        QUICK: "进行游戏",
        DAiLYCHALLENGE: "每日挑战",
        SEARCH: "搜索谜题",
        GAMEHISTORY: "历史游戏",
        DOWNLOADRULES: "下载游戏规则",
        XPARADOX: "觉得机器有误？ ",
        SHEET: "下载笔记纸",
        ABOUT: "关于图灵机",
        PLAYAGAME: "进行游戏",
        GAMETYPE: "游戏类型",
        COMPETITIVE: "对战",
        SOLO: "单人/合作",
        STEP1: "游戏模式",
        CLASSIC: "经典",
        EXTREME: "极限",
        NIGHTMARE: "噩梦",
        STEP2: "游戏难度",
        EASY: "简单",
        MEDIUM: "标准",
        HARD: "困难",
        STEP3: "验证者",
        PLAY: "生成游戏",
        BACKHOME: "返回主页",
        BACKTOGAME: "返回游戏",
        SHARE: "分享",
        COPIED: "已复制！ ",
        TEXT1: "从游戏盒中拿取标准卡牌和验证卡牌。",
        CHECKCODE: "检定密码",
        LOADING: "加载中",
        INPUTCODE: "输入你的密码",
        TESTCODE: "验证",
        TRYAGAIN: "再试一次",
        FALSECODE: "密码错误。",
        GOODCODE: "恭喜！密码正确！",
        SHOW_SOLUTION: "确定要查看答案吗？",
        YES: "是",
        NO: "否",
        SOLUTION: "答案解析",
        INPUTGAMECODE: "输入游戏编号",
        LOAD: "载入",
        ERROR_UNABLE: "无法载入谜题",
        BACK: "返回",
        BEATTHEMACHINE: "你是否打败了图灵机？",
        COMPARETOMACHINE: "与图灵机比较",
        INPUTSOLOB: "写下你每轮从每个验证者处得到的回答：",
        ADDROUND: "增加一轮",
        DELROUND: "移除最后一轮",
        WINSOLO2: "恭喜！你打败了图灵机！",
        VSMACHINETEXT1: "你找出了密码，共花费{$r}轮，{$q}个问题。",
        VSMACHINETEXT2: "而它花费了{$r}轮，{$q}个问题。",
        WINSOLO0: "噢不！你没有打败图灵机！",
        SHARERESULTS: "复制并将结果分享给你的好友，或分享在社交媒体上！",
        PASTEYOURRESULT: "现在将结果粘贴到社交平台上，与好友展开日常竞赛吧！ ",
        NIGHTMAREMODE: "噩梦模式：参见规则书第3页",
        EXTREMEMODE: "极限模式：参见规则书第3页",
        TURINGMACHINE: "图灵机",
        SOCIALWIN: "我打败了图灵机！",
        SOCIALLOSE: "我被机器打败了！ ",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.scorpionmasque.com/en/turingmachine",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_cns.pdf",
        PRINTABLECHALLENGES: "可打印的挑战集合",
        CREATECHALLENGE: "生成挑战",
        NEW: "新",
        CRITERIACARDS: "标准卡牌",
        CHOOSECARDS: "选择你想使用的标准卡牌",
        SELECTCARDS: "选择一张标准卡牌",
        CLICKTOSELECT: "点击选择卡牌"
    },
    3: {
        LANGCODE	:	"CNT"	,
        LANG	:	"繁體中文"	,
        QUICK	:	"進行遊戲"	,
        DAiLYCHALLENGE	:	"每日挑戰"	,
        SEARCH	:	"搜尋謎題"	,
        GAMEHISTORY	:	"歷史遊戲"	,
        DOWNLOADRULES	:	"下載遊戲規則"	,
        XPARADOX	:	"覺得機器有問題？"	,
        SHEET	:	"下載筆記紙"	,
        ABOUT	:	"關於圖靈解密"	,
        PLAYAGAME	:	"進行遊戲"	,
        GAMETYPE	:	"遊戲類型"	,
        COMPETITIVE	:	"對戰"	,
        SOLO	:	"單人/合作"	,
        STEP1	:	"遊戲模式"	,
        CLASSIC	:	"經典"	,
        EXTREME	:	"極限"	,
        NIGHTMARE	:	"夢魘"	,
        STEP2	:	"遊戲難度"	,
        EASY	:	"簡單"	,
        MEDIUM	:	"標準"	,
        HARD	:	"困難"	,
        STEP3	:	"驗證卡數量"	,
        PLAY	:	"生成遊戲"	,
        BACKHOME	:	"返回主畫面"	,
        BACKTOGAME	:	"返回遊戲"	,
        SHARE	:	"分享"	,
        COPIED	:	"已複製！"	,
        TEXT1	:	"從遊戲盒中拿取準則卡和驗證卡。"	,
        CHECKCODE	:	"驗證密碼"	,
        LOADING	:	"載入中"	,
        INPUTCODE	:	"輸入你的密碼"	,
        TESTCODE	:	"驗證"	,
        TRYAGAIN	:	"再試一次"	,
        FALSECODE	:	"密碼錯誤。"	,
        GOODCODE	:	"恭喜！密碼正確！"	,
        SHOW_SOLUTION	:	"你確定要查看解答嗎？"	,
        YES	:	"是"	,
        NO	:	"否"	,
        SOLUTION	:	"解答"	,
        INPUTGAMECODE	:	"輸入遊戲編號"	,
        LOAD	:	"載入"	,
        ERROR_UNABLE	:	"無法載入謎題"	,
        BACK	:	"返回"	,
        BEATTHEMACHINE	:	"你是否打敗了機器？"	,
        COMPARETOMACHINE	:	"與機器比較"	,
        INPUTSOLOB	:	"寫下你每輪從每個驗證卡處得到的回答："	,
        ADDROUND	:	"增加一輪"	,
        DELROUND	:	"移除最後一輪"	,
        WINSOLO2	:	"恭喜！你打敗了機器！"	,
        VSMACHINETEXT1	:	"你找出了密碼 共花費{$r}輪，和{$q}個問題。"	,
        VSMACHINETEXT2	:	"而機器花費了{$r}輪，和{$q}個問題。"	,
        WINSOLO0	:	"噢不！你沒有打敗機器！"	,
        SHARERESULTS	:	"複製並將結果分享給你的好友，或是分享到你的社群上！"	,
        PASTEYOURRESULT	:	"你現在可以將結果分享到你的社群上了，與好友展開日常競賽吧！"	,
        NIGHTMAREMODE	:	"夢魘模式：詳見規則書第3頁"	,
        EXTREMEMODE	:	"極限模式：詳見規則書第3頁"	,
        TURINGMACHINE	:	"圖靈解密"	,
        SOCIALWIN	:	"我打敗了機器！"	,
        SOCIALLOSE	:	"我被機器打敗了！",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.gokids.com.tw/product/detail1787",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_EN.pdf",
        CREATECHALLENGE: "建立挑戰",
        NEW: "新",
        CRITERIACARDS: "準則卡",
        CHOOSECARDS: "選擇你想要使用的準則卡",
        SELECTCARDS: "選擇準則卡",
        CLICKTOSELECT: "點擊以選擇",

        PRINTABLECHALLENGES: "可印製的挑戰集合"
    },
    4: {
        LANGCODE	:	"KR"	,
        LANG	:	"한국어"	,
        QUICK	:	"게임 시작하기"	,
        DAiLYCHALLENGE	:	"일일 도전"	,
        SEARCH	:	"문제 찾기"	,
        GAMEHISTORY	:	"게임 플레이 이력"	,
        DOWNLOADRULES	:	"게임 규칙서 다운로드"	,
        XPARADOX	:	"기계가 이상한 것 같아요!"	,
        SHEET	:	"기록지 다운로드"	,
        ABOUT	:	"튜링 머신 소개"	,
        PLAYAGAME	:	"게임 시작하기"	,
        GAMETYPE	:	"게임 종류"	,
        COMPETITIVE	:	"경쟁"	,
        SOLO	:	"1인 / 협력"	,
        STEP1	:	"게임 모드"	,
        CLASSIC	:	"클래식"	,
        EXTREME	:	"익스트림"	,
        NIGHTMARE	:	"나이트메어"	,
        STEP2	:	"난이도"	,
        EASY	:	"쉬움"	,
        MEDIUM	:	"보통"	,
        HARD	:	"어려움"	,
        STEP3	:	"검증기 수"	,
        PLAY	:	"문제 생성하기"	,
        BACKHOME	:	"첫 화면으로 돌아가기"	,
        BACKTOGAME	:	"게임으로 돌아가기"	,
        SHARE	:	"공유하기"	,
        COPIED	:	"복사 완료!"	,
        TEXT1	:	"다음 검증기 카드와 테스트 카드를 준비하세요.  "	,
        CHECKCODE	:	"정답 확인하기"	,
        LOADING	:	"불러오는 중"	,
        INPUTCODE	:	"코드를 입력하세요"	,
        TESTCODE	:	"확인하기"	,
        TRYAGAIN	:	"다시 시도하기"	,
        FALSECODE	:	"이런! 틀렸습니다."	,
        GOODCODE	:	"축하합니다! 진짜 코드를 찾았습니다."	,
        SHOW_SOLUTION	:	"주의! 정답 내용을 보실 건가요?"	,
        YES	:	"예"	,
        NO	:	"아니오"	,
        SOLUTION	:	"해답"	,
        INPUTGAMECODE	:	"게임 ID 입력하기"	,
        LOAD	:	"불러오기"	,
        ERROR_UNABLE	:	"문제를 불러올 수 없습니다."	,
        BACK	:	"돌아가기"	,
        BEATTHEMACHINE	:	"기계를 이겼을까요?"	,
        COMPARETOMACHINE	:	"기록을 기계와 비교하기"	,
        INPUTSOLOB	:	"라운드별로 여러분이 각 검증기에서 확인한 답을 기록하세요:"	,
        ADDROUND	:	"라운드 추가"	,
        DELROUND	:	"마지막 라운드 제거"	,
        WINSOLO2	:	"축하합니다! 기계를 이겼어요!"	,
        VSMACHINETEXT1	:	"당신은 <span class='bolder'>{$r}</span> 라운드, <span class='bolder'>{$q}</span> 번의 질문만에 진짜 코드를 찾았습니다."	,
        VSMACHINETEXT2	:	"기계는 <span class='bolder'>{$r}</span> 라운드, <span class='bolder'>{$q}</span> 번의 질문만에 진짜 코드를 찾았습니다."	,
        WINSOLO0	:	"이런! 기계가 이겼네요."	,
        SHARERESULTS	:	"게임 결과를 친구들이나 SNS에 공유하세요!"	,
        PASTEYOURRESULT	:	"복사한 게임 결과를 SNS에 업로드하여 친구들과 공유하세요! "	,
        NIGHTMAREMODE	:	"나이트메어 모드: 규칙서 3페이지를 참고하세요."	,
        EXTREMEMODE	:	"익스트림 모드 : 규칙서 3페이지를 참고하세요."	,
        TURINGMACHINE	:	"튜링 머신"	,
        SOCIALWIN	:	"기계는 내 상대가 아니지!"	,
        SOCIALLOSE	:	"이번엔 졌지만, 다음엔 꼭 이길거야!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.happybaobab.com/shop/item.php?it_id=CD05C-33551",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_KR.pdf",
        CREATECHALLENGE: "문제 생성하기",
        NEW: "NEW",
        CRITERIACARDS: "검증기 카드",
        CHOOSECARDS: "사용하려는 검증기 카드를 선택하세요",
        SELECTCARDS: "검증기 카드 선택",
        CLICKTOSELECT: "카드를 선택하려면 클릭하세요",

        PRINTABLECHALLENGES: "추가 문제 다운로드"
    },
    5: {
        LANGCODE	:	"DE"	,
        LANG	:	"Deutsch"	,
        QUICK	:	"START"	,
        DAiLYCHALLENGE	:	"Tagesaufgabe"	,
        SEARCH	:	"Aufgabe Suchen"	,
        GAMEHISTORY	:	"Verlauf"	,
        DOWNLOADRULES	:	"Download Spielanleitung"	,
        XPARADOX	:	"Du glaubst, der Computer irrt sich?"	,
        SHEET	:	"Download Notizblatt"	,
        ABOUT	:	"Über das Spiel"	,
        PLAYAGAME	:	"Einstellungen"	,
        GAMETYPE	:	"Spielweise"	,
        COMPETITIVE	:	"Gegeneinander"	,
        SOLO	:	"Alleine oder Kooperativ"	,
        STEP1	:	"Spielmodus"	,
        CLASSIC	:	"Klassisch"	,
        EXTREME	:	"Extrem"	,
        NIGHTMARE	:	"Albtraum"	,
        STEP2	:	"Schwierigkeitsgrad"	,
        EASY	:	"Einfach"	,
        MEDIUM	:	"Standard"	,
        HARD	:	"Schwierig"	,
        STEP3	:	"Tests"	,
        PLAY	:	"ERSTELLEN"	,
        BACKHOME	:	"Zurück zur Startseite"	,
        BACKTOGAME	:	"Zurück zur Aufgabe"	,
        SHARE	:	"Teilen"	,
        COPIED	:	"Kopiert!"	,
        TEXT1	:	"Nimm folgende Prüf- und Ergebniskarten aus der Schachtel:"	,
        CHECKCODE	:	"Code überprüfen"	,
        LOADING	:	"wird geladen"	,
        INPUTCODE	:	"Code eingeben"	,
        TESTCODE	:	"PRÜFEN"	,
        TRYAGAIN	:	"Versuch's noch einmal"	,
        FALSECODE	:	"Der Code ist nicht korrekt."	,
        GOODCODE	:	"Glückwunsch! Du hast den korrekten Code herausgefunden!."	,
        SHOW_SOLUTION	:	"Willst du wirklich die Auflösung sehen?"	,
        YES	:	"JA"	,
        NO	:	"NEIN"	,
        SOLUTION	:	"Lösung"	,
        INPUTGAMECODE	:	"Gib die Kennziffer der Aufgabe ein."	,
        LOAD	:	"LADE"	,
        ERROR_UNABLE	:	"Leider konnte keine entsprechende Aufgabe geladen werden."	,
        BACK	:	"Zurück"	,
        BEATTHEMACHINE	:	"Hast du die Maschine besiegt?"	,
        COMPARETOMACHINE	:	"Vergleiche dich mit der Maschine"	,
        INPUTSOLOB	:	"Markiere für jede Runde die Tests und deren Ergebnisse:"	,
        ADDROUND	:	"Runde hinzufügen"	,
        DELROUND	:	"Letzte runde entfernen"	,
        WINSOLO2	:	"Glückwunsch! Du hast die Maschine besiegt!"	,
        VSMACHINETEXT1	:	"Du hast den Code <br/>in <span class='bolder'>{$r}</span> Runde(n) und mit <span class='bolder'>{$q}</span> Frage(n) herausgefunden."	,
        VSMACHINETEXT2	:	"Die Maschine hat den Coden in <span class='bolder'>{$r}</span> Runden und mit <span class='bolder'>{$q}</span> Fragen ermittelt."	,
        WINSOLO0	:	"Schade. Die Maschine hat dich besiegt."	,
        SHARERESULTS	:	"Teile dein Ergebnis über Social Media mit deinen Freunden!"	,
        PASTEYOURRESULT	:	"Du kannst jetzt dein Ergebnis auf der Platform deiner Wahl einfügen und teilen. Fordere deine Freunde heraus."	,
        NIGHTMAREMODE	:	"Modus Albtraum: siehe Spielanleitung auf Seite 3"	,
        EXTREMEMODE	:	"Modus Extrem: siehe Spielanleitung auf Seite 3"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"Ich habe die maschine besiegt!"	,
        SOCIALLOSE	:	"Die maschine hat mich besiegt.",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://hutter-trade.com/sortiment/produkt/turing-machine-von-huch/",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_DE.pdf",
        CREATECHALLENGE: "Aufgabe erstellen",
        NEW: "New",
        CRITERIACARDS: "Prüfkarten",
        CHOOSECARDS: "Wähle die Prüfkarten aus, die du verwenden willst",
        SELECTCARDS: "Wähle eine Prüfkarte",
        CLICKTOSELECT: "Klicke auf die Karte, um sie auszuwählen",

        PRINTABLECHALLENGES: "Vorbereitete Aufgaben"    
    },
    6: {
        LANGCODE	:	"HU"	,
        LANG	:	"Magyar"	,
        QUICK	:	"JÁTSSZ"	,
        DAiLYCHALLENGE	:	"Napi kihívás"	,
        SEARCH	:	"Játék keresése"	,
        GAMEHISTORY	:	"Előzmények"	,
        DOWNLOADRULES	:	"Töltsd le a játékszabályt"	,
        XPARADOX	:	"Hibázott a gép?"	,
        SHEET	:	"Tölts le jegyzetlapokat"	,
        ABOUT	:	"A Turing Machine-ről"	,
        PLAYAGAME	:	"Játssz"	,
        GAMETYPE	:	"Játék típusa"	,
        COMPETITIVE	:	"Kompetitív"	,
        SOLO	:	"Szóló/Kooperatív"	,
        STEP1	:	"Játékmód"	,
        CLASSIC	:	"Klasszikus"	,
        EXTREME	:	"Extrém"	,
        NIGHTMARE	:	"Lehetetlen"	,
        STEP2	:	"Nehézségi szint"	,
        EASY	:	"Könnyű"	,
        MEDIUM	:	"Közepes"	,
        HARD	:	"Nehéz"	,
        STEP3	:	"Igazolók száma"	,
        PLAY	:	"GENERÁLJ JÁTÉKOT"	,
        BACKHOME	:	"Vissza a főoldalra"	,
        BACKTOGAME	:	"Vissza a játékhoz"	,
        SHARE	:	"MEGOSZTÁS"	,
        COPIED	:	"MÁSOLVA!"	,
        TEXT1	:	"Keresd ki a feltétel- és igazolókártyákat a dobozból."	,
        CHECKCODE	:	"Végső kód megadása"	,
        LOADING	:	"TÖLTÉS"	,
        INPUTCODE	:	"Alkosd meg a kódot"	,
        TESTCODE	:	"VIZSGÁLD MEG"	,
        TRYAGAIN	:	"Próbáld újra"	,
        FALSECODE	:	"Ez nem a helyes kód."	,
        GOODCODE	:	"Gratulálunk! Ez a helyes kód."	,
        SHOW_SOLUTION	:	"Biztos tudni akarod a megoldást?"	,
        YES	:	"IGEN"	,
        NO	:	"NEM"	,
        SOLUTION	:	"Megoldás"	,
        INPUTGAMECODE	:	"Írd be a játék számát"	,
        LOAD	:	"BETÖLTÉS"	,
        ERROR_UNABLE	:	"A játékot nem sikerült betölteni"	,
        BACK	:	"Vissza"	,
        BEATTHEMACHINE	:	"Legyőzted a GÉPET?"	,
        COMPARETOMACHINE	:	"Versenyezz a géppel"	,
        INPUTSOLOB	:	"Minden fordulóban írd le az igazolóktól kapott választ:"	,
        ADDROUND	:	"Forduló hozzáadása"	,
        DELROUND	:	"Utolsó forduló eltávolítása"	,
        WINSOLO2	:	"Gratulálunk! Legyőzted a gépet."	,
        VSMACHINETEXT1	:	"Megtaláltad a kódot <br/> <span class='bolder'>{$r}</span> forduló alatt és <span class='bolder'>{$q}</span> kérdésből."	,
        VSMACHINETEXT2	:	"A gép <span class='bolder'>{$r}</span> forduló alatt és <span class='bolder'>{$q}</span> kérdésből találta meg a kódot."	,
        WINSOLO0	:	"Óh! Nem sikerült legyőznöd a gépet."	,
        SHARERESULTS	:	"Másold be és oszd meg barátaiddal is az eredményt a közösségi felületeken."	,
        PASTEYOURRESULT	:	"Most bemásolhatod az eredményeidet a közösségi felületekre, hogy barátaiddal versenyezz a napi kihívásokban."	,
        NIGHTMAREMODE	:	"Lehetetlen küldetés mód: lásd a szabályokat a 3. oldalon"	,
        EXTREMEMODE	:	"Extrém mód: lásd a szabályokat a 3. oldalon"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"LEGYŐZTEM A GÉPET!"	,
        SOCIALLOSE	:	"A GÉP LEGYŐZÖTT.",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.reflexshop.hu/turing-machine",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_HU.pdf",
        CREATECHALLENGE: "Kihívás készítése",
        NEW: "ÚJ",
        CRITERIACARDS: "Feltételkártyák",
        CHOOSECARDS: "Válassz feltételkártyákat",
        SELECTCARDS: "Válassz egy feltételkártyát",
        CLICKTOSELECT: "Klikkelj a kártya választásáért",

        PRINTABLECHALLENGES: "Nyomtatható kihívástömb"
    },
    7: {
        LANGCODE	:	"IT"	,
        LANG	:	"Italiano"	,
        QUICK	:	"GIOCA"	,
        DAiLYCHALLENGE	:	"Sfida del giorno"	,
        SEARCH	:	"Cerca un problema"	,
        GAMEHISTORY	:	"Storico delle partite"	,
        DOWNLOADRULES	:	"Scarica il Regolamento"	,
        XPARADOX	:	"Pensi che la Macchina Commetta Errori?"	,
        SHEET	:	"Scarica i Fogli degli Appunti"	,
        ABOUT	:	"Su Turing Machine"	,
        PLAYAGAME	:	"Gioca"	,
        GAMETYPE	:	"Tipo di Partita"	,
        COMPETITIVE	:	"Competitiva"	,
        SOLO	:	"Solitario/Co-op"	,
        STEP1	:	"Modalità"	,
        CLASSIC	:	"Classica"	,
        EXTREME	:	"Estrema"	,
        NIGHTMARE	:	"Incubo"	,
        STEP2	:	"Difficoltà"	,
        EASY	:	"Facile"	,
        MEDIUM	:	"Normale"	,
        HARD	:	"Difficile"	,
        STEP3	:	"Verificatori"	,
        PLAY	:	"GENERA"	,
        BACKHOME	:	"Torna alla Homepage"	,
        BACKTOGAME	:	"Torna alla Partita"	,
        SHARE	:	"Condividi"	,
        COPIED	:	"Copiato!"	,
        TEXT1	:	"Prendi le carte criterio e le carte verifica indicate."	,
        CHECKCODE	:	"Verifica un codice"	,
        LOADING	:	"Caricamento"	,
        INPUTCODE	:	"Inserisci il Codice"	,
        TESTCODE	:	"VERIFICA"	,
        TRYAGAIN	:	"Riprova"	,
        FALSECODE	:	"Il codice non è corretto."	,
        GOODCODE	:	"Congratulazioni! Il codice è corretto."	,
        SHOW_SOLUTION	:	"Sei sicuro di voler vedere la soluzione?"	,
        YES	:	"SÌ"	,
        NO	:	"NO"	,
        SOLUTION	:	"Soluzione"	,
        INPUTGAMECODE	:	"Inserisci l'ID della Partita"	,
        LOAD	:	"RECUPERA"	,
        ERROR_UNABLE	:	"Impossibile recuperare il problema"	,
        BACK	:	"Indietro"	,
        BEATTHEMACHINE	:	"Hai Sconfitto la MACCHINA?"	,
        COMPARETOMACHINE	:	"Confronto con la Macchina"	,
        INPUTSOLOB	:	"Per ogni round, inserisci le risposte che hai ricevuto da ciascun verificatore:"	,
        ADDROUND	:	"Aggiungi un round"	,
        DELROUND	:	"Rimuovi l'ultimo round"	,
        WINSOLO2	:	"Congratulazioni! Hai sconfitto la Macchina!"	,
        VSMACHINETEXT1	:	"Hai scoperto il codice <br/>in <span class='bolder'>{$r}</span> round e <span class='bolder'>{$q}</span> interrogazione/i."	,
        VSMACHINETEXT2	:	"La Macchina l'ha scoperto in <span class='bolder'>{$r}</span> round e <span class='bolder'>{$q}</span> interrogazione/i."	,
        WINSOLO0	:	"Oh, no! Non hai sconfitto la Macchina."	,
        SHARERESULTS	:	"Copia i tuoi risultati e condividili con gli amici sui social!"	,
        PASTEYOURRESULT	:	"Ora puoi incollare i tuoi risultati sulle piattaforme social e sfidare i tuoi amici in una gara quotidiana!"	,
        NIGHTMAREMODE	:	"Modalità Incubo: vedi il regolamento a pagina 3"	,
        EXTREMEMODE	:	"Modalità Estrema: vedi il regolamento a pagina 3"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"Ho sconfitto la macchina!"	,
        SOCIALLOSE	:	"La macchina mi ha sconfitto!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.asmodee.it/giochi_turing_machine.php",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_IT.pdf",
        CREATECHALLENGE: "Crea una sfida",
        NEW: "Nuovo",
        CRITERIACARDS: "Carte Criterio",
        CHOOSECARDS: "Scegli le carte criterio che vuoi usare",
        SELECTCARDS: "Seleziona una carta criterio",
        CLICKTOSELECT: "Clicca per selezionare una carta",

        PRINTABLECHALLENGES: "Lista di Problemi Stampabile"
    },
    8: {
        LANGCODE	:	"ES"	,
        LANG	:	"Español"	,
        QUICK	:	"JUGAR"	,
        DAiLYCHALLENGE	:	"Reto diario"	,
        SEARCH	:	"Buscar un problema"	,
        GAMEHISTORY	:	"Historial de juego"	,
        DOWNLOADRULES	:	"Descargar el reglamento"	,
        XPARADOX	:	"¿Crees que la Máquina ha cometido un error?"	,
        SHEET	:	"Descargar las hojas de anotaciones"	,
        ABOUT	:	"Sobre Turing Machine"	,
        PLAYAGAME	:	"Jugar"	,
        GAMETYPE	:	"Modos de Juego"	,
        COMPETITIVE	:	"Competitivo"	,
        SOLO	:	"Solitario/Cooperativo"	,
        STEP1	:	"Modo"	,
        CLASSIC	:	"Clásico"	,
        EXTREME	:	"Extremo"	,
        NIGHTMARE	:	"Pesadilla"	,
        STEP2	:	"Dificultad"	,
        EASY	:	"Fácil"	,
        MEDIUM	:	"Normal"	,
        HARD	:	"Difícil"	,
        STEP3	:	"Verificadores"	,
        PLAY	:	"GENERAR"	,
        BACKHOME	:	"Volver a la página principal"	,
        BACKTOGAME	:	"Volver a la partida"	,
        SHARE	:	"Comparte"	,
        COPIED	:	"¡Copiado!"	,
        TEXT1	:	"Toma las siguientes cartas de Criterio y de Verificación."	,
        CHECKCODE	:	"Comprobar un código"	,
        LOADING	:	"Cargando"	,
        INPUTCODE	:	"Introduce tu Código"	,
        TESTCODE	:	"VERIFICAR"	,
        TRYAGAIN	:	"Intentarlo de nuevo"	,
        FALSECODE	:	"Este Código no es correcto."	,
        GOODCODE	:	"¡Enhorabuena! Este es el Código correcto."	,
        SHOW_SOLUTION	:	"¿De verdad quieres ver la solución?"	,
        YES	:	"SÍ"	,
        NO	:	"NO"	,
        SOLUTION	:	"Solución"	,
        INPUTGAMECODE	:	"Introduce el número de la partida."	,
        LOAD	:	"CARGAR"	,
        ERROR_UNABLE	:	"No ha sido posible cargar el Problema."	,
        BACK	:	"Volver"	,
        BEATTHEMACHINE	:	"¿Has conseguido vencer a la Máquina?"	,
        COMPARETOMACHINE	:	"Comparar con la Máquina"	,
        INPUTSOLOB	:	"Por cada ronda, escribe las respuestas obtenidas de cada Verificador:"	,
        ADDROUND	:	"Añade una ronda"	,
        DELROUND	:	"Elimina la última ronda"	,
        WINSOLO2	:	"¡Enhorabuena! ¡Has vencido a la Máquina!"	,
        VSMACHINETEXT1	:	"Has descifrado el Código <br/>en <span class='bolder'>{$r}</span> ronda(s) y <span class='bolder'>{$q}</span> pregunta(s)."	,
        VSMACHINETEXT2	:	"La Máquina lo ha descifrado en <span class='bolder'>{$r}</span> ronda(s) y <span class='bolder'>{$q}</span> pregunta(s)."	,
        WINSOLO0	:	"¡Lástima! La Máquina te ha vencido."	,
        SHARERESULTS	:	"¡Comparte tus resultados con tus amistades y en las redes sociales!"	,
        PASTEYOURRESULT	:	"¡Ya puedes compartir tus resultados en las redes sociales y retar a tus amistades en los retos diarios!"	,
        NIGHTMAREMODE	:	"Modo Pesadilla: consulta el reglamento, pág. 3"	,
        EXTREMEMODE	:	"Modo Extremo: consulta el reglamento, pág. 3"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"¡He vencido a la Máquina!"	,
        SOCIALLOSE	:	"¡La Máquina me ha vencido!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://tranjisgames.com/tienda/turing-machine/",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_ES.pdf",
        CREATECHALLENGE: "Crear un reto",
        NEW: "Nuevo",
        CRITERIACARDS: "Cartas de Criterio",
        CHOOSECARDS: "Elige qué cartas de Criterio quieres usar",
        SELECTCARDS: "Selecciona una carta de Criterio",
        CLICKTOSELECT: "Pulsa para seleccionar otra carta",

        PRINTABLECHALLENGES: "Descargar retos adicionales"
    },
    9: {
        LANGCODE	:	"GR"	,
        LANG	:	"Ελληνικά"	,
        QUICK	:	"ΠΑΙΞΤΕ"	,
        DAiLYCHALLENGE	:	"ΗΜΕΡΗΣΙΑ ΠΡΟΚΛΗΣΗ"	,
        SEARCH	:	"ΑΝΑΖΗΤΗΣΗ ΠΡΟΒΛΗΜΑΤΩΝ"	,
        GAMEHISTORY	:	"ΙΣΤΟΡΙΚΟ"	,
        DOWNLOADRULES	:	"Κατεβάστε τους Κανόνες"	,
        XPARADOX	:	"Νομίζετε ότι η Μηχανή έκανε Λάθος;"	,
        SHEET	:	"Κατεβάστε Σελίδες Σημειώσεων"	,
        ABOUT	:	"Σχετικά με τη Μηχανή του Τούρινγκ"	,
        PLAYAGAME	:	"Παίξτε"	,
        GAMETYPE	:	"Τύπος Παιχνιδιού"	,
        COMPETITIVE	:	"ΑΝΤΑΓΩΝΙΣΤΙΚΟ"	,
        SOLO	:	"ΓΙΑ ΕΝΑΝ/ΣΥΝΕΡΓΑΣΙΑΣ"	,
        STEP1	:	"Είδος"	,
        CLASSIC	:	"ΚΛΑΣΣΙΚΟ"	,
        EXTREME	:	"ΑΚΡΑΙΟ"	,
        NIGHTMARE	:	"ΕΦΙΑΛΤΗΣ"	,
        STEP2	:	"Δυσκολία"	,
        EASY	:	"ΕΥΚΟΛΟ"	,
        MEDIUM	:	"ΚΑΝΟΝΙΚΟ"	,
        HARD	:	"ΔΥΣΚΟΛΟ"	,
        STEP3	:	"Ελεγκτές"	,
        PLAY	:	"ΔΗΜΙΟΥΡΓΗΣΤΕ"	,
        BACKHOME	:	"Πίσω στην Αρχική Σελίδα"	,
        BACKTOGAME	:	"Πίσω στo Παιχνίδι"	,
        SHARE	:	"ΜΟΙΡΑΣΤΕΙΤΕ"	,
        COPIED	:	"ΑΝΤΙΓΡΑΦΗΚΕ!"	,
        TEXT1	:	"Βρείτε κάρτες Κριτηρίων και Ελεγκτών στο κουτί."	,
        CHECKCODE	:	"ΔΟΚΙΜΑΣΤΕ ΕΝΑΝ ΚΩΔΙΚΟ"	,
        LOADING	:	"Φορτώνει"	,
        INPUTCODE	:	"Εισάγετε τον κωδικό σας"	,
        TESTCODE	:	"ΕΠΙΒΕΒΑΙΩΣΤΕ"	,
        TRYAGAIN	:	"ΔΟΚΙΜΑΣΤΕ ΞΑΝΑ"	,
        FALSECODE	:	"Αυτός ο κωδικός δεν είναι σωστός."	,
        GOODCODE	:	"ΣΥΓΧΑΡΗΤΗΡΙΑ! ΑΥΤΟΣ ΕΙΝΑΙ Ο ΣΩΣΤΟΣ ΚΩΔΙΚΟΣ."	,
        SHOW_SOLUTION	:	"Θέλετε σίγουρα να δείτε την λύση;"	,
        YES	:	"ΝΑΙ"	,
        NO	:	"ΟΧΙ"	,
        SOLUTION	:	"ΛΥΣΗ"	,
        INPUTGAMECODE	:	"Εισάγετε Αριθμό Παρτίδας"	,
        LOAD	:	"ΦΟΡΤΩΣΤΕ"	,
        ERROR_UNABLE	:	"Το πρόβλημα δεν μπορεί να φορτωθεί"	,
        BACK	:	"Πίσω"	,
        BEATTHEMACHINE	:	"Νικήσατε τη ΜΗΧΑΝΗ;"	,
        COMPARETOMACHINE	:	"Συγκριθείτε με τη μηχανή"	,
        INPUTSOLOB	:	"Για κάθε γύρο, γράψτε τις απαντήσεις που πήρατε από κάθε Ελεγκτή:"	,
        ADDROUND	:	"ΠΡΟΣΘΕΣΤΕ ΕΝΑ ΓΥΡΟ"	,
        DELROUND	:	"ΑΦΑΙΡΕΣΤΕ ΤΟΝ ΤΕΛΕΥΤΑΙΟ ΓΥΡΟ"	,
        WINSOLO2	:	"Συγχαρητήρια! Νικήσατε τη μηχανή!"	,
        VSMACHINETEXT1	:	"Βρήκατε τον κωδικό <br/>σε <span class='bolder'>{$r}</span> γύρους  και <span class='bolder'>{$q}</span> ερωτήσεις."	,
        VSMACHINETEXT2	:	"Η μηχανή τον βρήκε σε <span class='bolder'>{$r}</span> γύρους και <span class='bolder'>{$q}</span> ερωτήσεις."	,
        WINSOLO0	:	"Ωχ όχι! Δεν νικήσατε τη μηχανή."	,
        SHARERESULTS	:	"Αντιγράψτε και μοιραστείτε τα αποτελέσματά σας με τους φίλους σας ή στα social media!"	,
        PASTEYOURRESULT	:	"Μπορείτε τώρα να επικολλήσετε τα αποτελέσματά σας στις πλατφόρμες των social media και να ανταγωνιστείτε τους φίλους σας!"	,
        NIGHTMAREMODE	:	"Εφιάλτης: δείτε σελ. 3 των κανόνων"	,
        EXTREMEMODE	:	"Ακραίο: δείτε σελ. 3 των κανόνων"	,
        TURINGMACHINE	:	"Η ΜΗΧΑΝΗ ΤΟΥ ΤΟΥΡΙΝΓΚ"	,
        SOCIALWIN	:	"ΝΙΚΗΣΑ ΤΗ ΜΗΧΑΝΗ!"	,
        SOCIALLOSE	:	"Η ΜΗΧΑΝΗ ΜΕ ΝΙΚΗΣΕ!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://kaissagames.com/b2c_gr/turing-machine.html",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_GR.pdf",
        CREATECHALLENGE: "ΔΗΜΙΟΥΡΓΗΣΤΕ ΜΙΑ ΠΡΟΚΛΗΣΗ",
        NEW: "ΝΕΟ",
        CRITERIACARDS: "Κάρτες Κριτηρίων",
        CHOOSECARDS: "Επιλέξτε τις κάρτες Κριτηρίων που θέλετε να χρησιμοποιήσετε",
        SELECTCARDS: "Επιλέξτε μια κάρτα Κριτηρίων",
        CLICKTOSELECT: "Κάντε κλικ για να επιλέξετε μια κάρτα",

        PRINTABLECHALLENGES: "Επιπλέον Προκλήσεις"
    },
    10: {
        LANGCODE	:	"BR"	,
        LANG	:	"Português"	,
        QUICK	:	"JOGAR"	,
        DAiLYCHALLENGE	:	"Desafio diário"	,
        SEARCH	:	"Buscar problema"	,
        GAMEHISTORY	:	"Histórico de jogo"	,
        DOWNLOADRULES	:	"Baixar Regras do Jogo"	,
        XPARADOX	:	"Acredita que a Máquina Errou?"	,
        SHEET	:	"Baixar Folhas de Anotações"	,
        ABOUT	:	"Sobre Turing Machine"	,
        PLAYAGAME	:	"Jogar"	,
        GAMETYPE	:	"Modo de Jogo"	,
        COMPETITIVE	:	"Competitivo"	,
        SOLO	:	"Solo/Cooperativo"	,
        STEP1	:	"Modo"	,
        CLASSIC	:	"Clásico"	,
        EXTREME	:	"Extremo"	,
        NIGHTMARE	:	"Pesadelo"	,
        STEP2	:	"Dificuldade"	,
        EASY	:	"Fácil"	,
        MEDIUM	:	"Médio"	,
        HARD	:	"Difícil"	,
        STEP3	:	"Verificadores"	,
        PLAY	:	"GERAR"	,
        BACKHOME	:	"Voltar para Home"	,
        BACKTOGAME	:	"Voltar para o Jogo"	,
        SHARE	:	"Compartilhar"	,
        COPIED	:	"Copiado!"	,
        TEXT1	:	"Pegue as cartas de Critério e Verificação na caixa."	,
        CHECKCODE	:	"Testar um Código"	,
        LOADING	:	"Carregando"	,
        INPUTCODE	:	"Inserir seu Código"	,
        TESTCODE	:	"CONFERIR"	,
        TRYAGAIN	:	"Tente de novo"	,
        FALSECODE	:	"Este código está incorreto."	,
        GOODCODE	:	"Parabéns! Este é o Código correto."	,
        SHOW_SOLUTION	:	"Você tem certeza de que quer ver a resposta?"	,
        YES	:	"SIM"	,
        NO	:	"NÃO"	,
        SOLUTION	:	"Solução"	,
        INPUTGAMECODE	:	"Inserir Número do Jogo"	,
        LOAD	:	"CARREGAR"	,
        ERROR_UNABLE	:	"Não foi possível carregar o problema."	,
        BACK	:	"Voltar"	,
        BEATTHEMACHINE	:	"Você venceu a MÁQUINA?"	,
        COMPARETOMACHINE	:	"Compare seu resultado com a Máquina"	,
        INPUTSOLOB	:	"A cada rodada, anote as respostas que recebeu de cada Verificador:"	,
        ADDROUND	:	"Adicionar uma rodada"	,
        DELROUND	:	"Remover a última rodada"	,
        WINSOLO2	:	"Parabéns! Você venceu a máquina!"	,
        VSMACHINETEXT1	:	"Você descobriu o código <br/>em <span class='bolder'>{$r}</span> rodada(s) e com <span class='bolder'>{$q}</span> pergunta(s)."	,
        VSMACHINETEXT2	:	"A Máquina o descobriu em <span class='bolder'>{$r}</span> rodada(s) e com <span class='bolder'>{$q}</span> pergunta(s)."	,
        WINSOLO0	:	"Ah, que pena! Você não venceu a máquina."	,
        SHARERESULTS	:	"Copie e compartilhe seus resultados com amigos e em suas redes sociais!"	,
        PASTEYOURRESULT	:	"Você pode colar seus resultados em qualquer rede social e competir com seus amigos nos desafios diários!"	,
        NIGHTMAREMODE	:	"Modo Pesadelo: veja a página 3 do livro de regras"	,
        EXTREMEMODE	:	"Modo Extremo: veja a página 3 do livro de regras"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"Eu venci a Máquina!"	,
        SOCIALLOSE	:	"A Máquina me venceu!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.mundogalapagos.com.br/turing-machine/produto/TRM001",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_BR.pdf",
        CREATECHALLENGE: "Criar um desafio",
        NEW: "Novo",
        CRITERIACARDS: "Cartas de Critério",
        CHOOSECARDS: "Escolha as Cartas de Critério que quer utilizar",
        SELECTCARDS: "Selecione uma Carta de Critério",
        CLICKTOSELECT: "Clique para selecionar uma carta",

        PRINTABLECHALLENGES: "Desafios para Impressão"
    },
    11: {
        LANGCODE	:	"JP"	,
        LANG	:	"日本語"	,
        QUICK	:	"設問を作る"	,
        DAiLYCHALLENGE	:	"デイリーチャレンジ"	,
        SEARCH	:	"設問番号で検索"	,
        GAMEHISTORY	:	"設問の生成履歴"	,
        DOWNLOADRULES	:	"Download Game Rules"	,
        XPARADOX	:	"Think That the Machine is Wrong?"	,
        SHEET	:	"考察シートのダウンロード"	,
        ABOUT	:	"このゲームについて"	,
        PLAYAGAME	:	"設問を作る"	,
        GAMETYPE	:	"ゲームタイプ"	,
        COMPETITIVE	:	"対戦"	,
        SOLO	:	"ソロ/協力"	,
        STEP1	:	"モード"	,
        CLASSIC	:	"通常"	,
        EXTREME	:	"エクストリーム"	,
        NIGHTMARE	:	"ナイトメア"	,
        STEP2	:	"難易度"	,
        EASY	:	"易しい"	,
        MEDIUM	:	"標準"	,
        HARD	:	"難しい"	,
        STEP3	:	"検証機の数"	,
        PLAY	:	"生成"	,
        BACKHOME	:	"トップに戻る"	,
        BACKTOGAME	:	"ゲームに戻る"	,
        SHARE	:	"シェアする"	,
        COPIED	:	"コピー完了！"	,
        TEXT1	:	"下記の要件カードと判定カードを <br/> 用意してください。"	,
        CHECKCODE	:	"解答する"	,
        LOADING	:	"読み込み中…"	,
        INPUTCODE	:	"秘密コードを入力してください"	,
        TESTCODE	:	"検証"	,
        TRYAGAIN	:	"別のコードを試す"	,
        FALSECODE	:	"不正解です。"	,
        GOODCODE	:	"おめでとう！ 正解です！"	,
        SHOW_SOLUTION	:	"本当に答えを見ますか？"	,
        YES	:	"はい"	,
        NO	:	"いいえ"	,
        SOLUTION	:	"答え"	,
        INPUTGAMECODE	:	"設問番号を入力してください"	,
        LOAD	:	"読み込む"	,
        ERROR_UNABLE	:	"読み込めませんでした"	,
        BACK	:	"戻る"	,
        BEATTHEMACHINE	:	"マシンとの勝負の結果は…"	,
        COMPARETOMACHINE	:	"マシンと勝負"	,
        INPUTSOLOB	: ["ラウンドごとに検証機から得た", <br/>, "判定を入力してください。"]	,
        ADDROUND	:	"ラウンドを追加"	,
        DELROUND	:	"最終行を消す"	,
        WINSOLO2	:	"おめでとう！  <br/> あなたの勝利です！"	,
        VSMACHINETEXT1	:	"あなたが正解までにかかったのは<br/> <span class='bolder'>{$r}</span>ラウンド、<span class='bolder'>{$q}</span>問です。"	,
        VSMACHINETEXT2	:	"マシンが正解までにかかったのは<br/> <span class='bolder'>{$r}</span>ラウンド、<span class='bolder'>{$q}</span>問です。"	,
        WINSOLO0	:	"残念！ あなたの負けです。"	,
        SHARERESULTS	:	["結果をコピーして友だちに、", <br/>, "またはSNSでシェアしよう"]	,
        PASTEYOURRESULT	:	["結果をSNSで投稿して、", <br/>, "友だちと競い合おう！"]	,
        NIGHTMAREMODE	:	"ナイトメアモードについては説明書の11ページを読んでください。"	,
        EXTREMEMODE	:	"エクストリームモードについては説明書の11ページを読んでください。"	,
        TURINGMACHINE	:	"チューリングマシン"	,
        SOCIALWIN	:	"マシンに勝利！"	,
        SOCIALLOSE	:	"マシンに敗北！",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://sugorokuya.jp/p/turing_machine/",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_JP.pdf",
        CREATECHALLENGE: "要件の指定で作る",
        NEW: "NEW",
        CRITERIACARDS: "要件カード",
        CHOOSECARDS: "今回使う要件カードを選ぶ",
        SELECTCARDS: "カードを選択",
        CLICKTOSELECT: "さらにカードを選ぶ",

        PRINTABLECHALLENGES: "印刷して遊べる設問集"
    },
    12: {
        LANGCODE	:	"PL"	,
        LANG	:	"Polski"	,
        QUICK	:	"ZAGRAJ"	,
        DAiLYCHALLENGE	:	"Wyzwanie dnia"	,
        SEARCH	:	"Wyszukaj zagadkę"	,
        GAMEHISTORY	:	"Historia rozgrywek"	,
        DOWNLOADRULES	:	"Pobierz instrukcję"	,
        XPARADOX	:	"Sądzisz, że maszyna się myli?"	,
        SHEET	:	"Pobierz arkusz do notowania"	,
        ABOUT	:	"O grze Turing Machine"	,
        PLAYAGAME	:	"Zagraj"	,
        GAMETYPE	:	"Rodzaj gry"	,
        COMPETITIVE	:	"Rywalizacja"	,
        SOLO	:	"Solo/Kooperacja"	,
        STEP1	:	"Tryb gry"	,
        CLASSIC	:	"Klasyczny"	,
        EXTREME	:	"Ekstremalny"	,
        NIGHTMARE	:	"Koszmarny"	,
        STEP2	:	"Trudność"	,
        EASY	:	"Łatwa"	,
        MEDIUM	:	"Standardowa"	,
        HARD	:	"Trudna"	,
        STEP3	:	"Weryfikatory"	,
        PLAY	:	"GENERUJ"	,
        BACKHOME	:	"Wróć do strony głównej"	,
        BACKTOGAME	:	"Wróć do gry"	,
        SHARE	:	"PODZIEL SIĘ"	,
        COPIED	:	"SKOPIOWANE!"	,
        TEXT1	:	"Weź karty kryteriów i weryfikacji z pudełka."	,
        CHECKCODE	:	"Zweryfikuj kod"	,
        LOADING	:	"ŁADOWANIE"	,
        INPUTCODE	:	"Wprowadź swój kod"	,
        TESTCODE	:	"ZWERYFIKUJ"	,
        TRYAGAIN	:	"Spróbuj ponownie"	,
        FALSECODE	:	"Ten kod jest niepoprawny."	,
        GOODCODE	:	"Gratulacje! To poprawny kod."	,
        SHOW_SOLUTION	:	"Czy na pewno chcesz poznać rozwiązanie?"	,
        YES	:	"TAK"	,
        NO	:	"NIE"	,
        SOLUTION	:	"Rozwiązanie"	,
        INPUTGAMECODE	:	"Wprowadź numer gry"	,
        LOAD	:	"WCZYTAJ"	,
        ERROR_UNABLE	:	"Nie można wczytać zagadki"	,
        BACK	:	"Wróć"	,
        BEATTHEMACHINE	:	"Czy MASZYNA została pokonana?"	,
        COMPARETOMACHINE	:	"Porównaj swój wynik z maszyną"	,
        INPUTSOLOB	:	"Zapisz odpowiedzi otrzymane z weryfikatorów w każdej rundzie:"	,
        ADDROUND	:	"Dodaj rundę"	,
        DELROUND	:	"Usuń ostatnią rundę"	,
        WINSOLO2	:	"Gratulacje! Maszyna pokonana!"	,
        VSMACHINETEXT1	:	"Kod odnaleziony! Twój wynik to <br/> <span class='bolder'>{$r}</span>rund(y) i<span class='bolder'>{$q}</span>pytań."	,
        VSMACHINETEXT2	:	"Wynik maszyny to<br/> <span class='bolder'>{$r}</span>rund(y) i <span class='bolder'>{$q}</span>pytań."	,
        WINSOLO0	:	"Niestety maszyna nie została pokonana."	,
        SHARERESULTS	:	"Podziel się swoimi wynikami ze znajomymi!"	,
        PASTEYOURRESULT	:	"Możesz podzielić się swoimi wynikami ze znajomymi i wspólnie stawić czoła dziennym wyzwaniom!"	,
        NIGHTMAREMODE	:	"Tryb koszmarny: patrz str. 3 instrukcji"	,
        EXTREMEMODE	:	"Tryb ekstremalny: patrz str. 3 instrukcji"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"UDAŁO MI SIĘ POKONAĆ MASZYNĘ!"	,
        SOCIALLOSE	:	"MASZYNA MNIE POKONAŁA",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://luckyduckgames.com/pl/gry/503-turing-machine",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_PL.pdf",
        CREATECHALLENGE: "Stwórz wyzwanie",
        NEW: "NOWE",
        CRITERIACARDS: "Karty kryteriów",
        CHOOSECARDS: "Wskaż, których kart kryteriów chcesz użyć",
        SELECTCARDS: "Wybierz kartę kryterium",
        CLICKTOSELECT: "Kliknij, by wybrać kartę",

        PRINTABLECHALLENGES: "Pobierz dodatkowe wyzwania"
    },
    13: {
        LANGCODE	:	"NL"	,
        LANG	:	"Dutch"	,
        QUICK	:	"SPEEL"	,
        DAiLYCHALLENGE	:	"DAGELIJKSE UITDAGING"	,
        SEARCH	:	"ZOEK EEN UITDAGING"	,
        GAMEHISTORY	:	"SPELGESCHIEDENIS"	,
        DOWNLOADRULES	:	"Spelregels downloaden"	,
        XPARADOX	:	"Denk je dat de machine het verkeerd heeft?"	,
        SHEET	:	"Notitiebladen downloaden"	,
        ABOUT	:	"Over Turing Machine"	,
        PLAYAGAME	:	"Speel"	,
        GAMETYPE	:	"Speltype"	,
        COMPETITIVE	:	"Competitief"	,
        SOLO	:	"Solo/Coöperatief"	,
        STEP1	:	"Variant"	,
        CLASSIC	:	"Klassiek"	,
        EXTREME	:	"Extreem"	,
        NIGHTMARE	:	"Nachtmerrie"	,
        STEP2	:	"Moelijkheid"	,
        EASY	:	"Makkelijk"	,
        MEDIUM	:	"Standaard"	,
        HARD	:	"Moeilijk"	,
        STEP3	:	"Machines"	,
        PLAY	:	"GENEREREN"	,
        BACKHOME	:	"Terug naar homepage"	,
        BACKTOGAME	:	"Terug naar spel"	,
        SHARE	:	"DELEN"	,
        COPIED	:	"GEKOPIEERD!"	,
        TEXT1	:	"Pak de criterium- en controlekaarten uit de doos."	,
        CHECKCODE	:	"Een code testen"	,
        LOADING	:	"LADEN"	,
        INPUTCODE	:	"Voer je code in"	,
        TESTCODE	:	"CONTROLEREN"	,
        TRYAGAIN	:	"Opnieuw proberen"	,
        FALSECODE	:	"Deze code is niet correct."	,
        GOODCODE	:	"Gefeliciteerd! Dit is de correcte code."	,
        SHOW_SOLUTION	:	"Weet je zeker dat je de oplossing wilt zien?"	,
        YES	:	"JA"	,
        NO	:	"NEE"	,
        SOLUTION	:	"Oplossing"	,
        INPUTGAMECODE	:	"Spel-ID invoeren"	,
        LOAD	:	"LADEN"	,
        ERROR_UNABLE	:	"Uitdaging kan niet geladen worden"	,
        BACK	:	"Terug"	,
        BEATTHEMACHINE	:	"Heb je de MACHINE verslagen?"	,
        COMPARETOMACHINE	:	"Met de machine vergelijken"	,
        INPUTSOLOB	:	"Noteer voor elke ronde de antwoorden die je van elke machine krijgt:"	,
        ADDROUND	:	"Een ronde toevoegen"	,
        DELROUND	:	"Laatste ronde verwijderen"	,
        WINSOLO2	:	"Gefeliciteerd! Je hebt de machine verslagen!"	,
        VSMACHINETEXT1	:	"Je hebt de code gevonden in <br/> <span class='bolder'>{$r}</span>ronde(s) en <span class='bolder'>{$q}</span>test(en)."	,
        VSMACHINETEXT2	:	"De machine vond het in<br/> <span class='bolder'>{$r}</span>ronde(s) en <span class='bolder'>{$q}</span>test(en)."	,
        WINSOLO0	:	"Helaas! Je hebt de machine niet verslagen."	,
        SHARERESULTS	:	"Kopieer en deel je resultaten met je vrienden of op je social media!"	,
        PASTEYOURRESULT	:	"Je kunt nu je resultaten op je social media-platforms plaatsen en het tegen je vrienden opnemen in een dagelijkse uitdaging!"	,
        NIGHTMAREMODE	:	"Nachtmerrie-variant: zie bladzijde 3 van de spelregels"	,
        EXTREMEMODE	:	"Extreem-variant: zie bladzijde 3 van de spelregels"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"IK HEB DE MACHINE VERSLAGEN!"	,
        SOCIALLOSE	:	"DE MACHINE HEEFT MIJ VERSLAGEN!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.scorpionmasque.com/en/turingmachine",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_NL.pdf",
        CREATECHALLENGE: "KIES EEN UITDAGING",
        NEW: "NIEUW",
        CRITERIACARDS: "Criteriumkaarten",
        CHOOSECARDS: "Kies de criteriumkaarten die je wil gebruiken",
        SELECTCARDS: "Selecteer een criteriumkaart",
        CLICKTOSELECT: "Klik om een kaart te selecteren",

        PRINTABLECHALLENGES: "Uitdagingen downloaden"
    },
    14: {
        LANGCODE	:	"TH"	,
        LANG	:	"ภาษาไทย"	,
        QUICK	:	"เริ่มเล่น"	,
        DAiLYCHALLENGE	:	"โจทย์ประจำวัน"	,
        SEARCH	:	"ค้นหาโจทย์"	,
        GAMEHISTORY	:	"ประวัติการเล่น"	,
        DOWNLOADRULES	:	"ดาวน์โหลดคู่มือเกม"	,
        XPARADOX	:	"คิดว่าเครื่องถอดรหัสทำงานผิดพลาด?"	,
        SHEET	:	"ดาวน์โหลดกระดาษจดข้อมูล"	,
        ABOUT	:	"เกี่ยวกับ Turing Machine"	,
        PLAYAGAME	:	"เริ่มเล่น"	,
        GAMETYPE	:	"โหมดการเล่น"	,
        COMPETITIVE	:	"โหมดแข่งขัน"	,
        SOLO	:	"เล่นคนเดียว/เล่นแบบร่วมมือ"	,
        STEP1	:	"โหมด"	,
        CLASSIC	:	"คลาสสิก"	,
        EXTREME	:	"Extreme"	,
        NIGHTMARE	:	"Nightmare"	,
        STEP2	:	"ความยาก"	,
        EASY	:	"ง่าย"	,
        MEDIUM	:	"ปานกลาง"	,
        HARD	:	"ยาก"	,
        STEP3	:	"จำนวนเครื่องตรวจสอบ"	,
        PLAY	:	"สร้างโจทย์"	,
        BACKHOME	:	"กลับไปหน้าแรก"	,
        BACKTOGAME	:	"กลับไปยังเกม"	,
        SHARE	:	"แชร์"	,
        COPIED	:	"คัดลอกแล้ว!"	,
        TEXT1	:	"ใช้การ์ดเงื่อนไขและแผ่นทดสอบจากในกล่อง"	,
        CHECKCODE	:	"ทดสอบรหัส"	,
        LOADING	:	"กำลังโหลด"	,
        INPUTCODE	:	"ใส่รหัสของคุณ"	,
        TESTCODE	:	"ทดสอบรหัส"	,
        TRYAGAIN	:	"ลองอีกครั้ง"	,
        FALSECODE	:	"รหัสนี้ไม่ถูกต้อง"	,
        GOODCODE	:	"ยินดีด้วย! รหัสนี้คือคำตอบที่ถูกต้อง"	,
        SHOW_SOLUTION	:	"แน่ใจแล้วใช่ไหม ว่าต้องการดูเฉลย?"	,
        YES	:	"ใช่"	,
        NO	:	"ไม่"	,
        SOLUTION	:	"เฉลย"	,
        INPUTGAMECODE	:	"ใส่ ID ของโจทย์"	,
        LOAD	:	"โหลด"	,
        ERROR_UNABLE	:	"ไม่สามารถโหลดโจทย์ได้"	,
        BACK	:	"กลับ"	,
        BEATTHEMACHINE	:	"Did you beat the MACHINE?"	,
        COMPARETOMACHINE	:	"เปรียบเทียบกับปัญญาประดิษฐ์"	,
        INPUTSOLOB	:	"ในแต่ละรอบ ให้ผู้เล่นจดบันทึกคำตอบที่ได้รับจากเครื่องตรวจสอบ"	,
        ADDROUND	:	"เพิ่มรอบการเล่น"	,
        DELROUND	:	"ลบรอบการเล่นล่าสุด"	,
        WINSOLO2	:	"ยินดีด้วย! คุณเอาชนะปัญญาประดิษฐ์ได้!"	,
        VSMACHINETEXT1	:	"คุณหารหัสได้สำเร็จภายใน <span class='bolder'>{$r}</span>รอบ และการป้อนรหัส<span class='bolder'>{$q}</span>ครั้ง"	,
        VSMACHINETEXT2	:	"ปัญญาประดิษฐ์หารหัสได้ภายใน<span class='bolder'>{$r}</span>รอบ และการป้อนรหัส<span class='bolder'>{$q}</span>ครั้ง"	,
        WINSOLO0	:	"ไม่นะ! คุณเอาชนะปัญญาประดิษฐ์ไม่ได้"	,
        SHARERESULTS	:	"คัดลอกและแบ่งปันผลงานของคุณกับเพื่อนๆ และใน Social Media!"	,
        PASTEYOURRESULT	:	"คุณสามารถแสดงผลงานของคุณบน Social Media และท้าทายเพื่อนของคุณในการแข่งขันได้ทุกวัน!"	,
        NIGHTMAREMODE	:	"โหมด Nightmare: ดูต่อที่หน้า 3 ในคู่มือ"	,
        EXTREMEMODE	:	"โหมด Extreme: ดูต่อที่หน้า 3 ในคู่มือ"	,
        TURINGMACHINE	:	"Turing Machine"	,
        SOCIALWIN	:	"ฉันเอาชนะปัญญาประดิษฐ์ได้!"	,
        SOCIALLOSE	:	"โดนปัญญาประดิษฐ์เล่นซะแล้ว!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.scorpionmasque.com/en/turingmachine",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_TH.pdf",
        CREATECHALLENGE: "สร้างโจทย์",
        NEW: "ใหม่",
        CRITERIACARDS: "การ์ดเงื่อนไข",
        CHOOSECARDS: "เลือกการ์ดเงื่อนไขที่ต้องการใช้",
        SELECTCARDS: "เลือกการ์ดเงื่อนไข",
        CLICKTOSELECT: "กดเพื่อเลือกการ์ดเพิ่ม",

        PRINTABLECHALLENGES: "โจทย์ที่สามารถพิมพ์เพิ่มได้"
    },
    15: {
        LANGCODE	:	"UA"	,
        LANG	:	"Українська"	,
        QUICK	:	"ГРАТИ"	,
        DAiLYCHALLENGE	:	"ЩОДЕННИЙ ВИКЛИК"	,
        SEARCH	:	"ЗНАЙТИ ЗАДАЧУ"	,
        GAMEHISTORY	:	"ІСТОРІЯ ІГОР"	,
        DOWNLOADRULES	:	"Завантажити правила гри"	,
        XPARADOX	:	"Гадаєте, машина помиляється?"	,
        SHEET	:	"Завантажити листи для нотаток"	,
        ABOUT	:	"Про «Машину Тюрінга»"	,
        PLAYAGAME	:	"Грати"	,
        GAMETYPE	:	"Тип гри"	,
        COMPETITIVE	:	"ЗМАГАЛЬНА"	,
        SOLO	:	"СОЛО/КООП"	,
        STEP1	:	"Режим гри"	,
        CLASSIC	:	"КЛАСИЧНИЙ"	,
        EXTREME	:	"СКЛАДНИЙ"	,
        NIGHTMARE	:	"ЕКСПЕРТНИЙ"	,
        STEP2	:	"Складність гри"	,
        EASY	:	"ЛЕГКО"	,
        MEDIUM	:	"ЗВИЧАЙНО"	,
        HARD	:	"ВАЖКО"	,
        STEP3	:	"Верифікатори"	,
        PLAY	:	"СТВОРИТИ"	,
        BACKHOME	:	"Повернутися на головну"	,
        BACKTOGAME	:	"Повернутися до гри"	,
        SHARE	:	"ПОДІЛИТИСЯ"	,
        COPIED	:	"СКОПІЙОВАНО!"	,
        TEXT1	:	"Візьміть карти критеріїв та верифікації <br>з коробки."	,
        CHECKCODE	:	"ПЕРЕВІРИТИ КОД"	,
        LOADING	:	"ЗАВАНТАЖУЄТЬСЯ"	,
        INPUTCODE	:	"Введіть свій код"	,
        TESTCODE	:	"Перевірити код"	,
        TRYAGAIN	:	"СПРОБУЙТЕ ЗНОВУ"	,
        FALSECODE	:	"Цей код неправильний."	,
        GOODCODE	:	"Вітаємо! Це правильний код."	,
        SHOW_SOLUTION	:	"Ви справді хочете подивитися розв’язок?"	,
        YES	:	"ТАК"	,
        NO	:	"НІ"	,
        SOLUTION	:	"Розв’язок"	,
        INPUTGAMECODE	:	"Введіть ID гри"	,
        LOAD	:	"ЗАВАНТАЖИТИ"	,
        ERROR_UNABLE	:	"Задача не завантажується"	,
        BACK	:	"Назад"	,
        BEATTHEMACHINE	:	"ПЕРЕМОГЛИ МАШИНУ?"	,
        COMPARETOMACHINE	:	"Порівняйте свій результат з машиною"	,
        INPUTSOLOB	:	"Щораунду записуйте відповіді кожного верифікатора:"	,
        ADDROUND	:	"Додайте раунд"	,
        DELROUND	:	"Вилучіть останній раунд"	,
        WINSOLO2	:	"Вітаємо! Ви перемогли машину!"	,
        VSMACHINETEXT1	:	"Ви відгадали код за <span class='bolder'>{$r}</span>раундів та<span class='bolder'>{$q}</span>запитань."	,
        VSMACHINETEXT2	:	"Машина відгадала код за<span class='bolder'>{$r}</span>раундів та<span class='bolder'>{$q}</span>запитань."	,
        WINSOLO0	:	"От халепа! Ви не перемогли машину!"	,
        SHARERESULTS	:	"Скопіюйте свій результат і поділіться ним із друзями в соцмережах!"	,
        PASTEYOURRESULT	:	"Тепер можете опублікувати свої результати в соцмережах і змагатися з друзями за найліпший результат щоденних викликів!"	,
        NIGHTMAREMODE	:	"Експертний режим (докладніше на с. 3 правил)"	,
        EXTREMEMODE	:	"Складний режим (докладніше на с. 3 правил)"	,
        TURINGMACHINE	:	"МАШИНА ТЮРІНГА"	,
        SOCIALWIN	:	"Я ПЕРЕМІГ МАШИНУ!"	,
        SOCIALLOSE	:	"МАШИНА ПЕРЕМОГЛА МЕНЕ",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://geekach.com.ua/mashyna-tiurinha-turing-machine/",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_UA.pdf",
        CREATECHALLENGE: "Створити виклик",
        NEW: "НОВЕ",
        CRITERIACARDS: "Карти критеріїв",
        CHOOSECARDS: "Виберіть карти критеріїв для цього виклику",
        SELECTCARDS: "Виберіть карту критерію",
        CLICKTOSELECT: "Натисніть, щоб вибрати карту",

        PRINTABLECHALLENGES: "Завантажити задачі для друку"
    },
    16: {
        LANGCODE	:	"RU"	,
        LANG	:	"Русский"	,
        QUICK	:	"ИГРАТЬ"	,
        DAiLYCHALLENGE	:	"ЕЖЕДНЕВНОЕ ИСПЫТАНИЕ"	,
        SEARCH	:	"ПОИСК ЗАДАЧИ"	,
        GAMEHISTORY	:	"ИСТОРИЯ ИГР"	,
        DOWNLOADRULES	:	"Скачать правила игры"	,
        XPARADOX	:	"Считаете, что машина допустила ошибку?"	,
        SHEET	:	"Скачать листы для заметок"	,
        ABOUT	:	"О «Машине Тьюринга»"	,
        PLAYAGAME	:	"Играть"	,
        GAMETYPE	:	"Вид игры"	,
        COMPETITIVE	:	"СОРЕВНОВАТЕЛЬНЫЙ"	,
        SOLO	:	"ОДИНОЧНЫЙ/КООПЕРАТИВНЫЙ"	,
        STEP1	:	"Режим"	,
        CLASSIC	:	"ОБЫЧНЫЙ"	,
        EXTREME	:	"СЛОЖНЫЙ"	,
        NIGHTMARE	:	"СВЕРХСЛОЖНЫЙ"	,
        STEP2	:	"Сложность"	,
        EASY	:	"НИЗКАЯ"	,
        MEDIUM	:	"ОБЫЧНАЯ"	,
        HARD	:	"ВЫСОКАЯ"	,
        STEP3	:	"Верификаторы"	,
        PLAY	:	"СОЗДАТЬ"	,
        BACKHOME	:	"Вернуться на домашнюю страницу"	,
        BACKTOGAME	:	"Продолжить игру"	,
        SHARE	:	"ПОДЕЛИТЬСЯ"	,
        COPIED	:	"СКОПИРОВАНО!"	,
        TEXT1	:	"Возьмите следующие карты критериев и верификации:"	,
        CHECKCODE	:	"ПРОВЕРИТЬ КОД"	,
        LOADING	:	"Загружается"	,
        INPUTCODE	:	"Введите ваш код"	,
        TESTCODE	:	"ПРОВЕРИТЬ КОД"	,
        TRYAGAIN	:	"ПОПРОБОВАТЬ СНОВА"	,
        FALSECODE	:	"Это неверный код."	,
        GOODCODE	:	"Поздравляем! Это верный код."	,
        SHOW_SOLUTION	:	"Вы точно хотите увидеть решение?"	,
        YES	:	"ДА"	,
        NO	:	"НЕТ"	,
        SOLUTION	:	"РЕШЕНИЕ"	,
        INPUTGAMECODE	:	"Введите номер игры"	,
        LOAD	:	"ЗАГРУЗИТЬ"	,
        ERROR_UNABLE	:	"Не удалось загрузить задачу"	,
        BACK	:	"Назад"	,
        BEATTHEMACHINE	:	"Удалось победить МАШИНУ?"	,
        COMPARETOMACHINE	:	"Сравнить с результатом машины"	,
        INPUTSOLOB	:	"Для каждого раунда впишите ответы, полученные от всех верификаторов:"	,
        ADDROUND	:	"ДОБАВИТЬ РАУНД"	,
        DELROUND	:	"УДАЛИТЬ ПОСЛЕДНИЙ РАУНД"	,
        WINSOLO2	:	"Поздравляем! Вам удалось победить машину!"	,
        VSMACHINETEXT1	:	"Вы вычислили код за <span class='bolder'>{$r}</span> раунд(ов) и <span class='bolder'>{$q}</span> запрос(ов)."	,
        VSMACHINETEXT2	:	"Машина вычислила код за <span class='bolder'>{$r}</span> раунд(ов) и <span class='bolder'>{$q}</span> запрос(ов)."	,
        WINSOLO0	:	"К сожалению, вам не удалось победить машину."	,
        SHARERESULTS	:	"Поделитесь своим результатом с друзьями и в социальных сетях!"	,
        PASTEYOURRESULT	:	"Теперь вы можете делиться своими результатами в социальных сетях и соревноваться с друзьями в ежедневных испытаниях!"	,
        NIGHTMAREMODE	:	"Сверхсложный режим: см. буклет с правилами, с. 3"	,
        EXTREMEMODE	:	"Сложный режим: см. буклет с правилами, с. 3"	,
        TURINGMACHINE	:	"МАШИНА ТЬЮРИНГА"	,
        SOCIALWIN	:	"Я ПОБЕДИЛ МАШИНУ!"	,
        SOCIALLOSE	:	"МАШИНА ПОБЕДИЛА МЕНЯ!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://www.lavkaigr.ru/projects/mashina-tyuringa/",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_RU.pdf",
        CREATECHALLENGE: "СОЗДАТЬ ИСПЫТАНИЕ",
        NEW: "новый",
        CRITERIACARDS: "Карты критериев",
        CHOOSECARDS: "Выберите карты критериев, которые вы хотите использовать",
        SELECTCARDS: "Выберите карту критериев",
        CLICKTOSELECT: "Нажмите, чтобы выбрать карту",

        PRINTABLECHALLENGES: "Скачать дополнительные задачи"
    },
    17: {
        LANGCODE	:	"CZ"	,
        LANG	:	"Čeština"	,
        QUICK	:	"HRÁT"	,
        DAiLYCHALLENGE	:	"DENNÍ ŠIFRA"	,
        SEARCH	:	"Načíst hru dle kódu"	,
        GAMEHISTORY	:	"HISTORIE HER"	,
        DOWNLOADRULES	:	"Stáhnout pravidla hry"	,
        XPARADOX	:	"Myslíte, že se stroj zmýlil?"	,
        SHEET	:	"Stáhnout poznámkové listy"	,
        ABOUT	:	"O hře Turing Machine"	,
        PLAYAGAME	:	"Hrát"	,
        GAMETYPE	:	"Typ hry"	,
        COMPETITIVE	:	"KOMPETITIVNÍ"	,
        SOLO	:	"SÓLO / KOOPERATIVNÍ"	,
        STEP1	:	"REŽIM HRY"	,
        CLASSIC	:	"STANDARD"	,
        EXTREME	:	"VÝZVA"	,
        NIGHTMARE	:	"Noční můra"	,
        STEP2	:	"OBTÍŽNOST"	,
        EASY	:	"SNADNÁ"	,
        MEDIUM	:	"NORMÁLNÍ"	,
        HARD	:	"OBTÍŽNÁ"	,
        STEP3	:	"Verifikátory"	,
        PLAY	:	"GENEROVAT"	,
        BACKHOME	:	"Zpět na domovskou stránku"	,
        BACKTOGAME	:	"Zpět do hry"	,
        SHARE	:	"SDÍLET"	,
        COPIED	:	"ZKOPÍROVÁNO!"	,
        TEXT1	:	"Vyjměte z krabice karty kritérií a výsledkové karty."	,
        CHECKCODE	:	"OVĚŘIT KÓD"	,
        LOADING	:	"Načítám"	,
        INPUTCODE	:	"Zadejte svůj kód"	,
        TESTCODE	:	"OVĚŘIT KÓD"	,
        TRYAGAIN	:	"ZKUSTE TO ZNOVU"	,
        FALSECODE	:	"Tento kód není správný."	,
        GOODCODE	:	"Gratulujeme! To je správný kód."	,
        SHOW_SOLUTION	:	"Určitě chcete zobrazit řešení?"	,
        YES	:	"ANO"	,
        NO	:	"NE"	,
        SOLUTION	:	"ŘEŠENÍ"	,
        INPUTGAMECODE	:	"Zadejte ID kód hry."	,
        LOAD	:	"NAHRÁT"	,
        ERROR_UNABLE	:	"Nebylo možno nahrát problém."	,
        BACK	:	"Zpět"	,
        BEATTHEMACHINE	:	"Porazili jste stroj?"	,
        COMPARETOMACHINE	:	"Porovnat se strojem"	,
        INPUTSOLOB	:	"Vložte odpovědi, které jste v každém jednotlivém kole od verifikátorů dostali:"	,
        ADDROUND	:	"PŘIDAT KOLO"	,
        DELROUND	:	"SMAZAT POSLEDNÍ KOLO"	,
        WINSOLO2	:	"Gratulujeme! Porazili jste stroj!"	,
        VSMACHINETEXT1	:	"Našli jste kód za <span class='bolder'>{$r}</span> kol(a) a <span class='bolder'>{$q}</span> otázek."	,
        VSMACHINETEXT2	:	"Stroj jej našel za <span class='bolder'>{$r}</span> kol(a) a <span class='bolder'>{$q}</span> otázek."	,
        WINSOLO0	:	"Bohužel! Stroj jste neporazili."	,
        SHARERESULTS	:	"Pochlubte se s výsledkem! Výsledky můžete snadno zkopírovat a vložit na svá sociální média."	,
        PASTEYOURRESULT	:	"Teď můžete vložit svůj zkopírovaný výsledek na sociální média a vyzvat se přátele v denní šifře!"	,
        NIGHTMAREMODE	:	"Režim Noční můra: viz pravidla hry, str. 3"	,
        EXTREMEMODE	:	"Režim Výzva: viz pravidla hry, str. 3"	,
        TURINGMACHINE	:	"TURING MACHINE"	,
        SOCIALWIN	:	"PORAZIL(A) JSEM STROJ!"	,
        SOCIALLOSE	:	"STROJ MĚ PORAZIL!",
        V4: "4",
        V5: "5",
        V6: "6",
        ABOUTLINK: "https://mindok.cz/hra/turing-machine/",
        XPARADOX_FILENAME: "TuringMachine_The_X_Paradox_CZ.pdf",
        CREATECHALLENGE: "VYTVOŘIT ŠIFRU",
        NEW: "ใหม่",
        CRITERIACARDS: "Karty kritérií",
        CHOOSECARDS: "Vyberte si karty kritérií, které chcete použít",
        SELECTCARDS: "Vyberte si kartu kritérií",
        CLICKTOSELECT: "Vyberte kartu kliknutím",

        PRINTABLECHALLENGES: "Sbírka šifer k vytištění"
    }
};

export default traduction;
